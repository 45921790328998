import { useTranslation } from 'react-i18next'
import { type DiscountStoryblok } from '#types/component-types-sb'
import Discount from '../navigation/mobile-navbar/discounts'
import PlentyButtonsAndLinks from '../typography/plenty-buttons-and-links'

const DiscountBlok = ({
	discounts,
	selectedCategory,
}: DiscountStoryblok & { selectedCategory: string | null }) => {
	const { t } = useTranslation('navbar')

	const now = new Date()

	const matchesCategory = (whoIsTheDiscountFor: string[]) => {
		if (!selectedCategory) return true 

		const categoryMappings: Record<string, string> = {
			mænd: 'Men',
			kvinder: 'Women',
			børn: 'Kids',
		}

		const normalizedCategory =
			categoryMappings[selectedCategory.toLowerCase()] ?? selectedCategory
		return whoIsTheDiscountFor.includes(normalizedCategory)
	}

	const personalDiscounts = discounts.filter((discount: DiscountStoryblok) => {
		const { endTimeForDiscount, storeDiscount, whoIsTheDiscountFor } =
			discount.content
		const endTime = new Date(endTimeForDiscount)
		return (
			!storeDiscount && endTime >= now && matchesCategory(whoIsTheDiscountFor)
		)
	})

	const storeDiscounts = discounts.filter((discount: DiscountStoryblok) => {
		const { endTimeForDiscount, storeDiscount, whoIsTheDiscountFor } =
			discount.content
		const endTime = new Date(endTimeForDiscount)
		return (
			storeDiscount && endTime >= now && matchesCategory(whoIsTheDiscountFor)
		)
	})
	return (
		<div className="flex h-auto flex-col gap-4 bg-beige-50-opacity px-4 py-3">
			{/* Personal Offers Section */}
			{personalDiscounts.length > 0 && (
				<div className="flex flex-col gap-2">
					<PlentyButtonsAndLinks as="nav-sm">
						{t('personal_offer')}
					</PlentyButtonsAndLinks>
					{/* Grid layout for personal offers */}
					<div className="grid grid-cols-2 gap-2">
						{personalDiscounts.map(
							(discount: DiscountStoryblok, index: number) => (
								<div
									key={index}
									className={
										discount.content.fullWidth ? 'col-span-2 w-full' : ''
									}
								>
									<Discount
										discount={discount.content}
										_uid={discount._uid}
										component={'discount'}
									/>
								</div>
							),
						)}
					</div>
				</div>
			)}

			{/* Store Discounts Section */}
			{storeDiscounts.length > 0 && (
				<div className="flex flex-col gap-2">
					<PlentyButtonsAndLinks as="nav-sm">
						{t('store_discounts')}
					</PlentyButtonsAndLinks>
					{/* Grid layout for store discounts */}
					<div className="grid grid-cols-2 gap-2">
						{storeDiscounts.map(
							(discount: DiscountStoryblok, index: number) => (
								<div
									key={index}
									className={
										discount.content.fullWidth ? 'col-span-2 w-full' : ''
									}
								>
									<Discount
										discount={discount.content}
										_uid={discount.uuid}
										component={'discount'}
									/>
								</div>
							),
						)}
					</div>
				</div>
			)}
		</div>
	)
}

export default DiscountBlok
