import { Link } from '@remix-run/react'
import SeparatorLine from '#app/components/common/separator-line'
import PlentyBody from '#app/components/typography/plenty-body'
import PlentyButtonsAndLinks from '#app/components/typography/plenty-buttons-and-links'
import { Icon } from '#app/components/ui/icon'
import useAmplitudeContext from '#app/hooks/use-amplitude-context'
import {
	type CategoryStoryblok,
	type ParentCategoryStoryblok,
} from '#types/component-types-sb'
import MobileNavFooter from '../footer/mobile-nav-footer'
import CategoryNavigation from './category-navigation'

interface CategoriesContentProps {
	category: CategoryStoryblok | ParentCategoryStoryblok
	parentCategory: string
}

type subCategoryType = CategoryStoryblok | ParentCategoryStoryblok
const CategoriesContent = ({
	category,
	parentCategory,
}: CategoriesContentProps) => {
	const subCategories = category.categories || []
	const { trackAmplitudeEvent } = useAmplitudeContext()
	return (
		<>
			<CategoryNavigation
				selectedCategory={category.title}
				parentCategory={parentCategory}
			/>
			<nav className="my-4 grid w-full gap-6 px-4 text-lg font-medium">
				{subCategories.length > 0 ? (
					subCategories.map(
						(
							subCategory: CategoryStoryblok | ParentCategoryStoryblok,
							index: number,
						) => (
							<div key={subCategory._uid} className="flex flex-col">
								<div className="relative flex cursor-pointer items-center justify-start px-2">
									<Icon
										name="three-lines"
										className="absolute -left-[24px] -z-10 h-[46px] w-[80px] text-beige-50"
									/>
									{subCategory.link?.url ? (
										<Link
											to={subCategory.link.url}
											onClickCapture={() =>
												trackAmplitudeEvent(
													'mobile navbar subcategory clicked',
													{
														subCategory: subCategory.title,
														parentCategory: parentCategory,
														subCategoryLink: subCategory.link?.url,
													},
												)
											}
										>
											<PlentyButtonsAndLinks
												as="nav-md"
												className={`relative z-10 font-bold text-${subCategory.textColor}`}
											>
												{subCategory.title}
											</PlentyButtonsAndLinks>
										</Link>
									) : (
										<PlentyButtonsAndLinks
											as="nav-md"
											className="relative z-10 font-bold"
										>
											{subCategory.title}
										</PlentyButtonsAndLinks>
									)}
								</div>
								<div className="mt-2 grid grid-cols-2 gap-2 px-2">
									{subCategory.categories?.map(
										(subSubCategory: subCategoryType) => (
											<Link
												to={subSubCategory.link?.url}
												key={subSubCategory._uid}
												onClickCapture={() =>
													trackAmplitudeEvent(
														'mobile navbar subcategory clicked',
														{
															subCategory: subSubCategory.title,
															parentCategory: subCategory.title,
															subCategoryLink: subSubCategory.link?.url,
														},
													)
												}
											>
												<PlentyButtonsAndLinks as="nav-md">
													{subSubCategory.title}
												</PlentyButtonsAndLinks>
											</Link>
										),
									)}
								</div>
								{index !== subCategories.length - 1 ? (
									<SeparatorLine className="mt-6" />
								) : (
									<div className="mt-6" />
								)}
							</div>
						),
					)
				) : (
					<PlentyBody size="sm">No subcategories available</PlentyBody>
				)}
			</nav>
			<MobileNavFooter smallVersion />
		</>
	)
}

export default CategoriesContent
