import { useTranslation } from 'react-i18next'
import PlentyBody from '#app/components/typography/plenty-body'
import PlentyButtonsAndLinks from '#app/components/typography/plenty-buttons-and-links'
import { Icon } from '#app/components/ui/icon'
import useAmplitudeContext from '#app/hooks/use-amplitude-context'
import { cn } from '#app/utils/misc'

type CategoryType = 'men' | 'kids' | 'women' | 'all'

interface GenderCategorySelectProps {
	onClose: () => void
	selectedCategory: CategoryType
	onCategoryChange: (category: CategoryType) => void
	searchTerm: string
}

export function GenderCategorySelect({
	onClose,
	selectedCategory,
	onCategoryChange,
	searchTerm,
}: GenderCategorySelectProps) {
	const { t } = useTranslation('navbar')
	const { trackAmplitudeEvent } = useAmplitudeContext()

	const categories: Array<{ id: CategoryType; label: string }> = [
		{ id: 'women', label: t('women_header') },
		{ id: 'men', label: t('men_header') },
		{ id: 'kids', label: t('kids_header') },
	]
	return (
		<div className="flex flex-row items-center justify-between gap-0 pb-4 md:justify-normal md:gap-4 md:pt-0">
			<PlentyBody size="xs">{t('you_are_searching_in')}</PlentyBody>
			{categories.map(category => (
				<PlentyButtonsAndLinks
					as="nav-md"
					key={category.id}
					onClick={() => onCategoryChange(category.id)}
					className={cn(
						'relative top-[2px] cursor-pointer items-center pb-1 transition-all',
						selectedCategory === category.id && [
							'font-bold',
							'top-[2px] after:absolute after:bottom-0 after:left-1/4 after:h-0.5 after:w-1/2 after:rounded-full after:bg-black',
						],
					)}
				>
					{category.label}
				</PlentyButtonsAndLinks>
			))}
			<Icon
				className="block md:hidden"
				onClick={() => {
					trackAmplitudeEvent('search abandon', { searchTerm })
					onClose()
				}}
				size="lg"
				name="close"
			/>
		</div>
	)
}
