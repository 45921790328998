import { useLocation } from '@remix-run/react'
import { useTranslation } from 'react-i18next'
import { GenderCategorySelect } from '#app/components/navigation/mobile-navbar/search/gender-category-select'
import { type CategoryType } from '#app/components/navigation/mobile-navbar/search/predictive-search-result-mobile'
import PlentyBody from '#app/components/typography/plenty-body'

interface NoResultFoundProps {
	searchTerm: string
	hideGender?: boolean
	selectedCategory?: CategoryType
	onCategoryChange?: (category: CategoryType) => void
	onClose?: () => void
}

const NoResultFound = ({
	searchTerm,
	hideGender,
	selectedCategory = 'women',
	onCategoryChange,
	onClose = () => {},
}: NoResultFoundProps) => {
	let { t } = useTranslation('search')
	const location = useLocation()

	return (
		<div className="flex flex-col gap-3 pt-3 md:pt-0">
			{!hideGender && location.pathname === '/' && (
				<div className="hidden md:flex">
					<GenderCategorySelect
						onClose={onClose}
						selectedCategory={selectedCategory}
						onCategoryChange={onCategoryChange ?? (() => {})}
						searchTerm={searchTerm}
					/>
				</div>
			)}
			<div className="flex flex-col gap-3">
				<PlentyBody size="md">{t('search_no_result')}</PlentyBody>
				<PlentyBody size="md" className="font-bold">
					'{searchTerm}'
				</PlentyBody>
			</div>
			<div className="flex flex-col gap-3">
				<div>{t('search_try')}</div>
				<div className="flex flex-col">
					<div className="flex items-center">
						<div className=" flex px-[4px]">&#8226;</div>
						<PlentyBody size="md">{t('search_double_check')} </PlentyBody>
					</div>
					<div className="flex items-center">
						<div className=" flex px-[4px]">&#8226;</div>
						<PlentyBody size="md">{t('search_again')}</PlentyBody>
					</div>
					<div className="flex items-center">
						<div className=" flex px-[4px]">&#8226;</div>
						<PlentyBody size="md">{t('search_explore_category')}</PlentyBody>
					</div>
				</div>
			</div>
		</div>
	)
}

export default NoResultFound
