import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PlentyButtonsAndLinks from '#app/components/typography/plenty-buttons-and-links'
import { Icon } from '#app/components/ui/icon'
import { useDebounceSearch } from '#app/utils/misc'

interface SearchSuggestionsProps {
	searchTerm: string
	onSuggestionClick: (suggestion: string, category: string) => void
	selectedCategory: 'women' | 'men' | 'kids' | 'all'
	onHasSuggestions?: (hasSuggestions: boolean) => void
}

type SuggestionType = {
	text: string
	icon: string
}

export function SearchSuggestions({
	searchTerm,
	onSuggestionClick,
	selectedCategory = 'all',
	onHasSuggestions,
}: SearchSuggestionsProps) {
	const { t } = useTranslation('categories')
	const [clerkSuggestions, setClerkSuggestions] = useState<string[]>([])
	const debouncedSearchTerm = useDebounceSearch(searchTerm, 400)
	useEffect(() => {
		async function fetchSuggestions() {
			if (!debouncedSearchTerm) {
				setClerkSuggestions([])
				return
			}

			try {
				const response = await fetch(
					`/resources/clerkSuggestion?query=${encodeURIComponent(
						debouncedSearchTerm,
					)}`,
				)
				const data = await response.json()

				// Filter results based on selectedCategory after getting them
				const filteredResults = (data.suggestions?.result || []).filter(
					(suggestion: string) => suggestion.startsWith(`${selectedCategory}-`),
				)

				setClerkSuggestions(filteredResults)
			} catch (error) {
				console.error('Error fetching suggestions:', error)
				setClerkSuggestions([])
			}
		}

		fetchSuggestions()
	}, [debouncedSearchTerm, selectedCategory])

	useEffect(() => {
		// Notify parent about suggestion state
		onHasSuggestions?.(clerkSuggestions.length > 0)
	}, [clerkSuggestions.length, onHasSuggestions])

	const simplifyText = (text: string) => {
		const parts = text.split('-')
		const lastWord = parts[parts.length - 1]

		// Check if the word exists in categories.json
		if (t(lastWord, { defaultValue: undefined })) {
			return t(lastWord)
		}

		// If last word isn't in categories, try second to last
		if (parts.length > 1) {
			const secondToLast = parts[parts.length - 2]
			if (t(secondToLast, { defaultValue: undefined })) {
				return t(secondToLast)
			}
		}

		// If no translation found, return the last word
		return lastWord
	}

	// Now we can just simplify and remove duplicates
	const suggestions: SuggestionType[] = Array.from(
		new Set(clerkSuggestions.map(suggestion => simplifyText(suggestion))),
	)
		.map(suggestion => ({
			text: suggestion,
			icon: 'magnifying-glass',
		}))
		.slice(0, 6)

	// Return null if there are no clerk suggestions
	if (!searchTerm || clerkSuggestions.length === 0) return null

	// 3) A helper to highlight the matched term by splitting around each match
	function highlightMatches(text: string, term: string) {
		if (!term) return text

		const lowerText = text.toLowerCase()
		const lowerTerm = term.toLowerCase()
		const termLength = term.length

		let segments: React.ReactNode[] = []
		let lastIndex = 0
		let matchIndex: number

		// Find each occurrence of `term` (case-insensitive) in `text`
		while ((matchIndex = lowerText.indexOf(lowerTerm, lastIndex)) !== -1) {
			// Push the text before the match
			segments.push(text.slice(lastIndex, matchIndex))
			// Push the matched part in <strong>
			segments.push(
				<strong key={matchIndex}>
					{text.slice(matchIndex, matchIndex + termLength)}
				</strong>,
			)
			// Move past this match
			lastIndex = matchIndex + termLength
		}
		// Push any remaining text after the last match
		segments.push(text.slice(lastIndex))

		return <>{segments}</>
	}

	return (
		<div className="flex flex-col gap-2 pt-3">
			<PlentyButtonsAndLinks as="nav-md">
				{t('search:search-suggestion')}
			</PlentyButtonsAndLinks>

			{suggestions.map((suggestion, index) => (
				<div
					key={index}
					className="flex cursor-pointer items-center gap-2"
					onClick={() => onSuggestionClick(suggestion.text, selectedCategory)}
				>
					<Icon className="text-body-md" size="sm" name={suggestion.icon} />
					<PlentyButtonsAndLinks as="nav-md" className="cursor-pointer">
						{highlightMatches(suggestion.text, searchTerm)}
					</PlentyButtonsAndLinks>
				</div>
			))}
		</div>
	)
}
