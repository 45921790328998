import {  useNavigate } from '@remix-run/react'
import { AnimatePresence, motion } from 'framer-motion'
import { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import SearchInputNew from '#app/components/common/forms/search-input-new'
import { Icon } from '#app/components/ui/icon'
import { Sheet, SheetTrigger, SheetContent } from '#app/components/ui/sheet'
import useAmplitudeContext from '#app/hooks/use-amplitude-context'
import { GenderCategorySelect } from './gender-category-select'
import { PredictiveSearchResultsMobile } from './predictive-search-result-mobile'
import { SearchSuggestions } from './search-suggestion'

type CategoryType = 'men' | 'kids' | 'women' | 'all'

interface MobileSearchProps {
	lastVisitedLanding: string
}

export default function MobileSearch({
	lastVisitedLanding,
}: MobileSearchProps) {
	const [open, setOpen] = useState(false)
	const [term, setTerm] = useState('')
	const [showSuggestions, setShowSuggestions] = useState(true)

	const navigate = useNavigate()
	const { t } = useTranslation('navbar')
	const { trackAmplitudeEvent } = useAmplitudeContext()

	const [selectedCategory, setSelectedCategory] =
		useState<CategoryType>('women')

	// Add useEffect to update category when lastVisitedLanding changes
	useEffect(() => {
		if (lastVisitedLanding === '/men-landing') {
			setSelectedCategory('men')
		} else if (lastVisitedLanding === '/kids-landing') {
			setSelectedCategory('kids')
		} else if (lastVisitedLanding === '/women-landing') {
			setSelectedCategory('women')
		}
	}, [lastVisitedLanding])

	// We'll attach our scroll listener on this <div>, not on <SheetContent>
	const scrollableRef = useRef<HTMLDivElement | null>(null)

	// Reset search and showSuggestions on sheet close
	useEffect(() => {
		if (!open) {
			setTerm('')
			setShowSuggestions(true)
		}
	}, [open])

	// Show suggestions again whenever user types
	useEffect(() => {
		// Each time 'term' changes, we want suggestions to be visible again
		setShowSuggestions(true)
	}, [term])

	// Once the user scrolls beyond 10px, hide suggestions forever (until the user re-types)
	const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
		const scrollTop = e.currentTarget.scrollTop
		if (scrollTop > 10) {
			setShowSuggestions(false)
		}
	}

	const isStyleNumber = (value: string) => /^\d{8}$/.test(value)
	const isCodeName = (value: string) =>
		/^[A-Z\s]+$/.test(value) && value.includes(' ')

	const getSearchType = (value: string) => {
		if (isStyleNumber(value)) return 'style'
		if (isCodeName(value)) return 'code'
		return 'regular'
	}

	const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setTerm(e.target.value)
	}

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		const searchType = getSearchType(term.toUpperCase())

		const url =
			searchType === 'regular'
				? `/clerk-search?query=${encodeURIComponent(
						term.toUpperCase(),
				  )}&category=${encodeURIComponent(selectedCategory)}`
				: `/clerk-search?query=${encodeURIComponent(
						term.toUpperCase(),
				  )}&type=${searchType}`

		navigate(url)
		setOpen(false)
	}

	const handleSuggestionClick = (suggestion: string, category: string) => {
		const url = `/clerk-search?query=${encodeURIComponent(
			suggestion,
		)}&category=${encodeURIComponent(category)}`
		navigate(url)
		setOpen(false)
	}

	const handleCategoryChange = (category: CategoryType) => {
		setSelectedCategory(category)
	}

	// Only show suggestions if we're in "regular" mode (i.e. not style/code).
	const shouldShowSuggestions = () => {
		return getSearchType(term.toUpperCase()) === 'regular'
	}

	return (
		<Sheet open={open} onOpenChange={setOpen}>
			<SheetTrigger className="md:hidden" asChild>
				<Icon
					onClick={() => trackAmplitudeEvent('search initiated')}
					name="magnifying-glass"
					className="h-6 w-6"
				/>
			</SheetTrigger>

			<SheetContent side="right" hiddenClose className="flex flex-col p-4">
				{/* Category Select */}
				<GenderCategorySelect
					onClose={() => setOpen(false)}
					selectedCategory={selectedCategory}
					onCategoryChange={handleCategoryChange}
					searchTerm={term}
				/>

				{/* Search Input */}
				<form onSubmit={handleSubmit}>
					<SearchInputNew
						type="text"
						value={term}
						placeholder={t('search_bar_placeholder')}
						autoFocus
						onChange={handleSearchChange}
					/>
				</form>
				<div
					ref={scrollableRef}
					onScroll={handleScroll}
					className="flex-1 overflow-y-auto"
				>
					<AnimatePresence mode="wait">
						{shouldShowSuggestions() && showSuggestions && (
							<motion.div
								key="search-suggestions"
								initial={{ opacity: 0, y: -8 }}
								animate={{ opacity: 1, y: 0 }}
								exit={{ opacity: 0, y: -8 }}
								transition={{ duration: 0.2 }}
							>
								<SearchSuggestions
									searchTerm={term}
									onSuggestionClick={suggestion =>
										handleSuggestionClick(suggestion, selectedCategory)
									}
									selectedCategory={selectedCategory}
								/>
							</motion.div>
						)}
					</AnimatePresence>

					<PredictiveSearchResultsMobile
						searchTerm={term}
						category={selectedCategory}
						onClose={() => setOpen(false)}
						onSearch={() => {
							handleSubmit(new Event('submit') as any)
							setOpen(false)
						}}
					/>
				</div>
			</SheetContent>
		</Sheet>
	)
}
