import { useFetcher, useLocation } from '@remix-run/react'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from '#app/components/ui/icon'
import { Sheet, SheetContent, SheetTrigger } from '#app/components/ui/sheet'
import { usePlentyMediaQuery } from '#app/hooks/use-plenty-media-query'
import { trackMobileNav } from '#app/utils/tracking/amplitude/mobile-navbar'
import {
	type DiscountStoryblok,
	type NavigationCategoriesStoryblok,
} from '#types/component-types-sb'
import MobileNavFooter from './footer/mobile-nav-footer'
import MainContent from './main-content'
import MobileNavLayout from './mobile-nav-layout'
import ScrollableContent from './scrollable-content'
import { Slide, SlideItems, SlideMainContent } from './slides'

interface ResponsiveNavbarProps {
	navItems: {
		link: string
		name: string
	}[]
	navigationData: NavigationCategoriesStoryblok[]
}
interface FetcherData {
	discounts: DiscountStoryblok[]
}
const DISCOUNT_CACHE_DURATION = 5 * 60 * 1000

const MobileNavBar = ({ navItems, navigationData }: ResponsiveNavbarProps) => {
	const [open, setOpen] = useState(false)
	const [selectedCategory, setSelectedCategory] = useState<string | null>(null)

	const isDesktop = usePlentyMediaQuery('(min-width: 820px)')
	const { t, i18n } = useTranslation('categories')
	const location = useLocation()
	const [prevUrl, setPrevUrl] = useState(location.pathname + location.search) // Track previous URL
	const [isCategoryManuallySelected, setIsCategoryManuallySelected] =
		useState(false)
	const [discounts, setDiscounts] = useState<DiscountStoryblok[]>([]) // State to cache discount data

	const handleCategoryClick = (title: string) => {
		setSelectedCategory(title)
		setIsCategoryManuallySelected(true) // Set flag when category is manually selected
	}
	const filteredNavigationData = navigationData.filter(
		item => !item.hideInMobile,
	)
	const fetcher = useFetcher<FetcherData>() // Provide the type parameter

	// Prefetch discount data when the component mounts
	const loadCachedDiscounts = useCallback(() => {
		const cachedDiscounts = sessionStorage.getItem('discounts')
		const discountCacheTimestamp = sessionStorage.getItem(
			'discountCacheTimestamp',
		)

		if (cachedDiscounts && discountCacheTimestamp) {
			const now = Date.now()
			const cacheAge = now - parseInt(discountCacheTimestamp, 10)

			if (cacheAge < DISCOUNT_CACHE_DURATION) {
				setDiscounts(JSON.parse(cachedDiscounts)) // Load cached discounts into state
				return true // Cache is valid, no need to fetch
			}
		}
		return false // Cache is missing or expired, need to fetch
	}, [])

	// Prefetch discount data or load from cache when the component mounts
	useEffect(() => {
		if (!isDesktop && discounts.length === 0) {
			const isCacheValid = loadCachedDiscounts() // Attempt to load from cache

			if (!isCacheValid && fetcher.state === 'idle') {
				// Only fetch if cache is not valid and fetcher is idle
				fetcher.load('/resources/storyblok/mobile-nav-discount')
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDesktop, discounts.length, loadCachedDiscounts])

	// Store fetched discounts in state and session storage
	useEffect(() => {
		if (fetcher.data?.discounts && discounts.length === 0) {
			setDiscounts(fetcher.data.discounts)
			sessionStorage.setItem(
				'discounts',
				JSON.stringify(fetcher.data.discounts),
			)
			sessionStorage.setItem('discountCacheTimestamp', Date.now().toString())
		}
	}, [fetcher.data, discounts.length])

	// Handle opening the mobile nav and fetch data if needed
	const handleOpenChange = (newOpen: boolean) => {
		setOpen(newOpen)

		if (
			newOpen &&
			!isDesktop &&
			discounts.length === 0 &&
			fetcher.state === 'idle'
		) {
			fetcher.load('/resources/storyblok/mobile-nav-discount')
		}

		// Track only when mobile nav is being opened
		if (newOpen && !isDesktop) {
			trackMobileNav(location.pathname + location.search)
		}
	}
	// close the sheet when the route changes
	useEffect(() => {
		setOpen(false)
	}, [location.pathname, location.search])
	useEffect(() => {
		if (!isCategoryManuallySelected) {
			if (location.pathname.includes('men-landing')) {
				setSelectedCategory(t('men'))
			} else if (location.pathname.includes('kids-landing')) {
				setSelectedCategory(t('kids'))
			} else if (location.pathname.includes('women-landing')) {
				setSelectedCategory(t('women'))
			} else {
				setSelectedCategory(t('women'))
			}
		}
	}, [location.pathname, isCategoryManuallySelected, t])

	useEffect(() => {
		// Close the drawer when the language changes
		const handleLanguageChange = () => {
			setOpen(false)
		}

		i18n.on('languageChanged', handleLanguageChange)

		return () => {
			i18n.off('languageChanged', handleLanguageChange)
		}
	}, [i18n])

	useEffect(() => {
		if (open && location.pathname + location.search !== prevUrl) {
			trackMobileNav(location.pathname + location.search) // Track navigation via mobile nav
			setPrevUrl(location.pathname + location.search) // Update previous URL
		}
	}, [location.pathname, location.search, open, prevUrl])

	useEffect(() => {
		if (open) {
			document.body.classList.add('no-scroll')
		} else {
			document.body.classList.remove('no-scroll')
		}

		return () => {
			document.body.classList.remove('no-scroll')
		}
	}, [open])

	useEffect(() => {
		if (isDesktop) {
			setOpen(false)
		}
	}, [isDesktop])

	if (isDesktop) {
		return null
	}
	return (
		<Sheet open={open} onOpenChange={handleOpenChange}>
			<SheetTrigger className="flex items-center justify-center">
				<Icon className="text-body-md block md:hidden" name="menu" size="lg" />
			</SheetTrigger>

			<SheetContent
				className="space-y sheet-content flex w-full max-w-full flex-col border-0 p-0 sm:max-w-full"
				side="right"
				hiddenClose
			>
				<Slide>
					<SlideItems>
						<SlideMainContent slideKey="main">
							<ScrollableContent className="h-full space-y-3">
								<MobileNavLayout
									selectedCategory={selectedCategory}
									onCategoryClick={handleCategoryClick}
									navigationData={filteredNavigationData}
									discounts={discounts}
								/>

								<MainContent
									selectedCategory={selectedCategory}
									navigationData={filteredNavigationData}
								/>
								<MobileNavFooter />
							</ScrollableContent>
						</SlideMainContent>
					</SlideItems>
				</Slide>
			</SheetContent>
		</Sheet>
	)
}

export default MobileNavBar
