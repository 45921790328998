import { useTranslation } from 'react-i18next'
import DiscountBlok from '#app/components/storyblok/discount'
import PlentyButtonsAndLinks from '#app/components/typography/plenty-buttons-and-links'
import { Icon } from '#app/components/ui/icon'
import useAmplitudeContext from '#app/hooks/use-amplitude-context'
import { cn } from '#app/utils/misc'
import {
	type DiscountStoryblok,
	type NavigationCategoriesStoryblok,
} from '#types/component-types-sb'
import NoCurrentDiscountsLive from './discounts/no-current-discounts-live'
import TopNavigationDiscount from './discounts/top-navigation-discount'
import MobileNavFooter from './footer/mobile-nav-footer'
import ScrollableContent from './scrollable-content'
import { SlideContent, SlideTrigger } from './slides'

interface DiscountTriggerProps {
	selectedCategory: string | null
	onCategoryClick: (title: string) => void
	navigationData: NavigationCategoriesStoryblok[]
	discounts: DiscountStoryblok[]
}

const DiscountTrigger = ({
	selectedCategory,
	onCategoryClick,
	navigationData,
	discounts,
}: DiscountTriggerProps) => {
	const { t } = useTranslation('navbar')
	const now = new Date()
	const normalizeCategory = (category: string | null): string | null => {
		if (!category) return null
		const categoryMappings: Record<string, string> = {
			mænd: 'Men',
			kvinder: 'Women',
			børn: 'Kids',
		}
		const lowerCategory = category.toLowerCase()
		return categoryMappings[lowerCategory] || category
	}

	const normalizedSelectedCategory = normalizeCategory(selectedCategory)

	const currentCategoryDiscounts = discounts.filter(
		(discount: DiscountStoryblok) => {
			const { startTimeForDiscount, endTimeForDiscount, whoIsTheDiscountFor } =
				discount.content
			const startTime = new Date(startTimeForDiscount)
			const endTime = new Date(endTimeForDiscount)
			const isWithinTime = startTime <= now && endTime >= now

			const matchesCategory = normalizedSelectedCategory
				? whoIsTheDiscountFor.includes(normalizedSelectedCategory)
				: true 

			return isWithinTime && matchesCategory
		},
	)

	const { trackAmplitudeEvent } = useAmplitudeContext()
	const hasValidDiscounts = currentCategoryDiscounts.length > 0

	return (
		<div>
			<SlideTrigger
				slideKey="discounts"
				className="flex flex-row items-center justify-between rounded bg-beige-50 bg-ampersandDiscountsMobile bg-no-repeat p-3"
				onClickCapture={() =>
					trackAmplitudeEvent('mobile navbar discounts slide triggered')
				}
			>
				<div className="relative w-fit">
					<PlentyButtonsAndLinks as="nav-md" className={cn(' text-black-90')}>
						{t('active_discounts')}
						{hasValidDiscounts ? (
							<span className="absolute -right-1 top-0 inline-flex -translate-y-1/2 translate-x-1/2 transform items-center justify-center self-center rounded-[32px] bg-mint-green-80p px-3 py-1 text-center text-xs font-bold leading-none text-black-90">
								{currentCategoryDiscounts.length}
							</span>
						) : null}
					</PlentyButtonsAndLinks>
				</div>
				<Icon name="right-arrow" size="lg" />
			</SlideTrigger>
			<SlideContent className="flex flex-col gap-3" slideKey="discounts">
				<ScrollableContent className="h-auto space-y-3">
					<TopNavigationDiscount
						selectedCategory={selectedCategory}
						onCategoryClick={onCategoryClick}
						navigationData={navigationData}
					/>
					{hasValidDiscounts ? (
						<DiscountBlok
							discounts={currentCategoryDiscounts}
							_uid=""
							component="discount"
							selectedCategory={selectedCategory}
						/>
					) : (
						<NoCurrentDiscountsLive
							selectedCategory={selectedCategory}
							_uid=""
							component="discount"
						/>
					)}
					<div className="flex pt-5">
						<MobileNavFooter />
					</div>
				</ScrollableContent>
			</SlideContent>
		</div>
	)
}

export default DiscountTrigger
