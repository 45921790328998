import { Link, useFetcher, useNavigation } from '@remix-run/react'
import { useAnimation } from 'framer-motion'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { InputField } from '#app/components/common/forms/input'
import { ProductCarousel } from '#app/components/product-carousel'
import PlentyBody from '#app/components/typography/plenty-body'
import PlentyButtonsAndLinks from '#app/components/typography/plenty-buttons-and-links'
import PlentyHeading from '#app/components/typography/plenty-heading'
import { Button } from '#app/components/ui/button'
import { Checkbox } from '#app/components/ui/checkbox'
import { Label } from '#app/components/ui/label'
import PlentyBodyLink from '#app/components/ui/plenty-body-link'
import useAmplitudeContext from '#app/hooks/use-amplitude-context'
import { type DiscountStoryblok } from '#types/component-types-sb'
import { type ClerkProduct } from '#types/product'

const NoCurrentDiscountsLive = ({ selectedCategory }: DiscountStoryblok) => {
	const { t } = useTranslation('navbar')
	const navigation = useNavigation()
	const isSubmitting = navigation.state === 'submitting'
	const [clerkProducts, setClerkProducts] = useState<
		ClerkProduct[] | undefined
	>(undefined)
	const { trackAmplitudeEvent } = useAmplitudeContext()
	const controls = useAnimation()
	const [email, setEmail] = useState('')
	const [consent, setConsent] = useState(false)
	const [error, setError] = useState<string | null>(null)
	const fetcher = useFetcher()

	// Initialize submitted state from sessionStorage
	const [submitted, setSubmitted] = useState<boolean>(() => {
		if (typeof window !== 'undefined') {
			return sessionStorage.getItem('newsletterSubmitted') === 'true'
		}
		return false
	})

	const mapCategoryToEnglish = (category: string) => {
		const categoryMap: Record<string, string> = {
			kvinder: 'women',
			mænd: 'men',
			børn: 'kids',
		}

		return categoryMap[category.toLowerCase()] || category.toLowerCase() // Return English category or fallback
	}
	const englishCategory = mapCategoryToEnglish(selectedCategory)

	// Update sessionStorage when submitted state changes
	useEffect(() => {
		if (typeof window !== 'undefined') {
			sessionStorage.setItem('newsletterSubmitted', submitted.toString())
		}
	}, [submitted])

	useEffect(() => {
		let isMounted = true // Add a flag to track if the component is mounted

		const endpoint = 'popular'
		const categoryFilter = `categoryKey=${encodeURIComponent(englishCategory)}`

		// URL with the sale filter
		const urlWithSale = `/resources/productCarouselDataClerk?endpoint=${encodeURIComponent(
			endpoint,
		)}&${categoryFilter}&on_sale=true`

		// URL without the sale filter
		const urlWithoutSale = `/resources/productCarouselDataClerk?endpoint=${encodeURIComponent(
			endpoint,
		)}&${categoryFilter}`

		const fetchData = async () => {
			try {
				// First, try fetching with the sale filter
				let response = await fetch(urlWithSale)

				// If the response is not okay, throw an error
				if (!response.ok) {
					throw new Error(`Network response was not ok: ${response.statusText}`)
				}
				let data = await response.json()
				// If there's no data with the sale filter, try without it
				if (data.length === 0) {
					response = await fetch(urlWithoutSale)

					if (!response.ok) {
						throw new Error(
							`Network response was not ok: ${response.statusText}`,
						)
					}
					data = await response.json()
				}

				// Set the products in state
				setClerkProducts(data)

				setTimeout(() => {
					if (isMounted) {
						controls.start('visible') // Trigger animation when data is ready
					}
				}, 650)
			} catch (error) {
				console.error('Failed to fetch products:', error)
				// Delay the update of setIsShuffling to false after 0.8 seconds
				setTimeout(() => {
					if (isMounted) {
						controls.start('visible')
					}
				}, 650)
				setClerkProducts([])
			}
		}

		fetchData()

		return () => {
			isMounted = false
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedCategory])

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()

		// Client-side email validation
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
		if (!emailRegex.test(email)) {
			setError('Please enter a valid email address')
			return
		}

		setError(null)
		setSubmitted(true)

		// Save submitted state in sessionStorage
		if (typeof window !== 'undefined') {
			sessionStorage.setItem('newsletterSubmitted', 'true')
		}
		trackAmplitudeEvent('newsletter signup', { from: 'discounts' })
		// Submit the form using fetcher, not causing a navigation
		fetcher.submit(e.currentTarget, {
			method: 'post',
			action: '/signup/klaviyo-signup',
		})
	}

	return (
		<div className="flex h-full flex-col gap-4 bg-beige-50-opacity py-3">
			<div className="flex flex-col gap-4 px-4">
				{submitted ? (
					<PlentyHeading as="h7">{t('thanks_for_sub')}</PlentyHeading>
				) : (
					<div className="flex flex-col gap-2">
						<PlentyButtonsAndLinks as="nav-sm">
							{t('no_discount_code')}
						</PlentyButtonsAndLinks>
						<fetcher.Form
							method="post"
							action="/signup/klaviyo-signup"
							onSubmit={handleSubmit}
							className="space-y-2"
						>
							<InputField
								name="email"
								value={email}
								onChange={e => setEmail(e.target.value)}
								placeholder={t('returns:email_input_placeholder')}
								error={undefined}
								variant="icon"
								icon="mail"
								disabled={isSubmitting}
							/>
							{error && (
								<div className="mt-1 text-sm text-red-90">
									{t('error_discount_mail')}
								</div>
							)}
							<div className="items-cente mt-6 flex ">
								<Checkbox
									className="mr-2 h-6 w-6 "
									id="newsletter"
									onCheckedChange={checked => {
										setConsent(checked)
									}}
								/>
								<Label className=" text-beige-100" htmlFor="newsletter">
									<PlentyBody size="xs">
										<Trans i18nKey="common:newsletter_consent">
											<Link
												to="/policy/newsletter-terms-and-conditions#consent"
												target="_blank"
												rel="noopener noreferrer"
												className="font-semibold underline"
											>
												newsletter-consent
											</Link>
											<Link
												to="/policy/newsletter-terms-and-conditions"
												target="_blank"
												rel="noopener noreferrer"
												className="font-semibold underline"
											>
												kundeservice
											</Link>
										</Trans>
									</PlentyBody>
								</Label>
							</div>
							<Button
								type="submit"
								disabled={!consent || !email || isSubmitting}
								fullWidth
								size="secondary"
								variant="primary"
							>
								{t('common:newsletter_submit')}
							</Button>
						</fetcher.Form>
					</div>
				)}
			</div>
			<div className="flex flex-col gap-4 px-4">
				<Link
					to={`products-sale/${englishCategory}`}
					className="flex flex-col gap-2"
				>
					<PlentyHeading as="h7">{t('new_items')}</PlentyHeading>
					<PlentyBodyLink as="nav-sm">{t('see_all_sale')}</PlentyBodyLink>
				</Link>

				<ProductCarousel clerkProducts={clerkProducts} />
			</div>
		</div>
	)
}

export default NoCurrentDiscountsLive
