import { Link } from '@remix-run/react'
import { useTranslation } from 'react-i18next'
import PromotionCard from '#app/components/navigation/nav-item/promotion-card.tsx'
import PlentyButtonsAndLinks from '#app/components/typography/plenty-buttons-and-links.tsx'
import useAmplitudeContext from '#app/hooks/use-amplitude-context'
import { useUtmParams } from '#app/hooks/useUtmParams'
import { trackBrandView } from '#app/utils/tracking/amplitude/brand-view'
import {
	type CategoryStoryblok,
	type ParentCategoryStoryblok,
} from '#types/component-types-sb'

const CategoryGrid = ({
	content,
	brands,
}: {
	content: CategoryStoryblok | ParentCategoryStoryblok | undefined
	brands: (CategoryStoryblok | ParentCategoryStoryblok) | undefined
}) => {
	const { trackAmplitudeEvent } = useAmplitudeContext()
	const { t } = useTranslation('navbar')
	const utmParams = useUtmParams()

	// Separate large categories from regular ones
	const [largeCategory, regularCategories] = (content?.categories || []).reduce(
		(
			acc: [
				(CategoryStoryblok | ParentCategoryStoryblok)[],
				(CategoryStoryblok | ParentCategoryStoryblok)[],
			],
			category: CategoryStoryblok | ParentCategoryStoryblok,
		) => {
			if (category.isLarge) {
				acc[0].push(category)
			} else {
				acc[1].push(category)
			}
			return acc
		},
		[[], []],
	)

	const columns = 3 // Number of main category columns
	const categoriesPerColumn = Math.ceil(
		regularCategories.length / (columns - largeCategory.length),
	)

	// Group regular categories into columns
	const groupedCategories = regularCategories.reduce(
		(acc: any, category: any, index: number) => {
			const columnIndex = Math.floor(index / categoriesPerColumn)
			if (!acc[columnIndex]) {
				acc[columnIndex] = []
			}
			acc[columnIndex].push(category)
			return acc
		},
		[],
	)

	const renderCategory = (
		category: CategoryStoryblok | ParentCategoryStoryblok,
		isLarge = false,
	) => (
		<div key={category._uid} className={` ${isLarge ? 'h-full' : ''}`}>
			<Link
				to={category.link?.cached_url || '/'}
				className="mb-3 block"
				onClickCapture={() =>
					trackAmplitudeEvent('navbar category clicked', {
						category: category.title,
						link: category.link?.cached_url,
					})
				}
			>
				<PlentyButtonsAndLinks as="nav-sm" className="font-semibold">
					{category.title}
				</PlentyButtonsAndLinks>
			</Link>
			<div className={isLarge ? 'space-y-2' : ''}>
				{category.categories?.map((subItem: any) => (
					<Link
						key={subItem._uid}
						to={subItem.link.cached_url}
						className="block"
						onClickCapture={() =>
							trackAmplitudeEvent('navbar category clicked', {
								category: subItem.title,
								link: subItem.link.cached_url,
							})
						}
					>
						<div className="group/sub my-2 flex flex-row items-center">
							<PlentyButtonsAndLinks
								as="nav-sm"
								className="text-black-70 hover:font-semibold group-hover/sub:text-black-90 group-hover/sub:opacity-100"
							>
								{subItem.title}
							</PlentyButtonsAndLinks>
						</div>
					</Link>
				))}
			</div>
		</div>
	)

	return (
		<div className="grid grid-cols-9 gap-8">
			{/* Trending now column */}
			<div className="col-span-1 space-y-4">
				{content?.specialLinks.map((item: CategoryStoryblok) => (
					<div key={item.title} className="">
						<Link
							to={item.link.cached_url || '/'}
							onClickCapture={() =>
								trackAmplitudeEvent('navbar category clicked', {
									category: item.title,
									link: item.link.cached_url,
								})
							}
						>
							<PlentyButtonsAndLinks as="nav-md">
								{item.title}
							</PlentyButtonsAndLinks>
						</Link>
					</div>
				))}
			</div>

			{/* Main category columns */}
			<div className="col-span-4">
				<div className="grid grid-cols-3 gap-8">
					{largeCategory &&
						largeCategory.map(
							(
								largeCat: CategoryStoryblok | ParentCategoryStoryblok,
								columnIndex: number,
							) => (
								<div key={columnIndex} className="space-y-8">
									{renderCategory(largeCat, true)}
								</div>
							),
						)}
					{groupedCategories.map(
						(
							column: (CategoryStoryblok | ParentCategoryStoryblok)[],
							columnIndex: number,
						) => (
							<div key={columnIndex} className="space-y-8">
								{column.map(
									(category: CategoryStoryblok | ParentCategoryStoryblok) =>
										renderCategory(category),
								)}
							</div>
						),
					)}
				</div>
			</div>

			{/* Popular brands column */}
			<div className="col-span-1">
				<PlentyButtonsAndLinks as="nav-sm" className="mb-3 font-semibold">
					{t('popular_brands')}
				</PlentyButtonsAndLinks>
				<div className="grid grid-cols-1 gap-2">
					{/* Brands */}
					{brands?.categories?.map((brand: any) => (
						<Link
							to={brand.link.cached_url}
							key={brand._uid}
							onClickCapture={() =>
								trackAmplitudeEvent('navbar brand clicked', {
									brand: brand.title,
									link: brand.link.cached_url,
								})
							}
						>
							<div
								className="flex h-12 items-center justify-center rounded bg-beige-50-opacity p-2"
								onClick={() => trackBrandView(brand.link, utmParams)}
							>
								<img
									src={brand.image.filename}
									alt={brand.title}
									className="max-h-full max-w-full object-contain"
								/>
							</div>
						</Link>
					))}
				</div>
			</div>

			{/* Promotion card */}
			{content?.promotions && content.promotions[0] && (
				<div className="col-span-3">
					<PromotionCard
						heading={content.promotions[0].promoHeading}
						subHeading={content.promotions[0].promoSubHeading}
						link={content.promotions[0].promoButtonLink.cached_url}
						cta={content.promotions[0].promoButtonText}
						image={content.promotions[0].promoImage.filename}
					/>
				</div>
			)}
		</div>
	)
}

export default CategoryGrid
