import { Link } from '@remix-run/react'
import React from 'react'
import SubMenu from '#app/components/navigation/nav-item/submenu.tsx'
import useAmplitudeContext from '#app/hooks/use-amplitude-context'
import { cn } from '#app/utils/misc.ts'
import {
	type CategoryStoryblok,
	type NavigationCategoriesStoryblok,
	type ParentCategoryStoryblok,
} from '#types/component-types-sb'
import PlentyBody from '../typography/plenty-body'
import PlentyButtonsAndLinks from '../typography/plenty-buttons-and-links'

interface HeaderKeysRowProps {
	keys: {
		link: string
		name: string
		isSale?: boolean
		categories?: (CategoryStoryblok | ParentCategoryStoryblok)[]
	}[]
	categoriesToShow: NavigationCategoriesStoryblok | undefined
	brandsToShow: CategoryStoryblok | ParentCategoryStoryblok | undefined
}
const HeaderKeysRow = ({
	keys,
	categoriesToShow,
	brandsToShow,
}: HeaderKeysRowProps) => {
	const [hoveredKey, setHoveredKey] = React.useState<string | null>(null)
	const { trackAmplitudeEvent } = useAmplitudeContext()
	const handleMouseEnter = (name: string, link: string) => {
		setHoveredKey(name)
		trackAmplitudeEvent('navbar hover category', {
			category: name,
			link: link,
		})
	}
	return (
		<div className="group" onMouseLeave={() => setHoveredKey(null)}>
			<div className="peer relative flex gap-6">
				{keys.map(({ link, name, isSale, categories }) => {
					return (
						<HeaderKey
							key={link}
							link={link}
							name={name}
							isSale={isSale}
							onMouseEnter={() =>
								categories ? handleMouseEnter(name, link) : setHoveredKey(null)
							}
							isActive={hoveredKey === name}
						/>
					)
				})}
			</div>
			{categoriesToShow && hoveredKey && (
				<SubMenu
					content={categoriesToShow.categories?.find(
						key => key.title === hoveredKey,
					)}
					brands={brandsToShow}
				/>
			)}
		</div>
	)
}

interface HeaderKeyProps {
	link: string
	name: string
	isSale?: boolean
	onMouseEnter: () => void
	isActive?: boolean
}

const HeaderKey = ({
	link,
	name,
	isSale,
	onMouseEnter,
	isActive,
}: HeaderKeyProps) => {
	const { trackAmplitudeEvent } = useAmplitudeContext()
	return (
		<Link
			to={link}
			onMouseEnter={onMouseEnter}
			className="relative inline-block"
			onClickCapture={() =>
				trackAmplitudeEvent('navbar click category', {
					category: name,
					link: link,
				})
			}
		>
			<div className="relative">
				<PlentyButtonsAndLinks
					as="nav-sm"
					className={cn(
						`border-b border-b-transparent hover:border-black-90 focus:border-mint-green-50 ${
							isSale ? 'text-red-90' : ''
						}`,
						isActive ? 'border-black-90' : '',
					)}
				>
					{name}
				</PlentyButtonsAndLinks>
				{link.includes('/stores') && (
					<PlentyBody
						size="xs"
						className="absolute -right-[2px] top-[4px] -translate-y-1/2 translate-x-[95%] transform whitespace-nowrap rounded-full bg-mint-green-50 px-[6px] py-[2px]"
					>
						shop click & collect!
					</PlentyBody>
				)}
			</div>
		</Link>
	)
}

export default HeaderKeysRow

export const getWomenCategories = (
	t: (arg0: string) => string,
	categories: (CategoryStoryblok | ParentCategoryStoryblok)[] | undefined,
) => [
	{
		link: `/products-sale/women`,
		name: t('header_sale'),
		isSale: true,
	},
	{ link: `/products/women?sortingOrder=new`, name: t('header_new') },
	{ link: `/products/women?sortingOrder=popular`, name: t('header_popular') },
	...(categories || []).map(it => ({
		link: it.link?.url,
		name: it.title,
		categories: it.categories,
	})),
	{ link: '/brands?gender=women', name: t('brands_header') },
	{ link: '/stores?gender=women', name: t('stores_header') },
]

export const getMenCategories = (
	t: (arg0: string) => string,
	categories: (CategoryStoryblok | ParentCategoryStoryblok)[] | undefined,
) => [
	{
		link: `/products-sale/men`,
		name: t('header_sale'),
		isSale: true,
	},
	{ link: `/products/men?sortingOrder=new`, name: t('header_new') },
	{ link: `/products/men?sortingOrder=popular`, name: t('header_popular') },
	...(categories || []).map(it => ({
		link: it.link?.url,
		name: it.title,
		categories: it.categories,
	})),
	{ link: '/brands?gender=men', name: t('brands_header') },
	{ link: '/stores?gender=men', name: t('stores_header') },
]

export const getKidsCategories = (
	t: (arg0: string) => string,
	categories: (CategoryStoryblok | ParentCategoryStoryblok)[] | undefined,
) => [
	{
		link: `/products-sale/kids`,
		name: t('header_sale'),
		isSale: true,
	},
	{ link: `/products/kids?sortingOrder=new`, name: t('header_new') },
	{ link: `/products/kids?sortingOrder=popular`, name: t('header_popular') },
	...(categories || []).map(it => ({
		link: it.link?.url,
		name: it.title,
		categories: it.categories,
	})),
	{ link: '/brands?gender=kids', name: t('brands_header') },
	{ link: '/stores?gender=kids', name: t('stores_header') },
]
