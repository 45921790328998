import { useState } from 'react'
import { Icon } from '#app/components/ui/icon'
import { Input } from '#app/components/ui/input'
import { Label } from '#app/components/ui/label'
import { cn } from '#app/utils/misc'

interface SearchInputNewProps
	extends React.InputHTMLAttributes<HTMLInputElement> {
	placeholder: string
	id?: string
	onClear?: () => void
	showClear?: boolean
}

const SearchInputNew = ({
	id,
	className,
	value,
	defaultValue,
	placeholder,
	onChange,
	onClear,
	showClear,
	...rest
}: SearchInputNewProps) => {
	const [isFocused, setIsFocused] = useState(false)

	return (
		<div className="z-50 flex w-full justify-end self-end">
			<Label htmlFor={id} className="sr-only">
				{id || 'Search'}
			</Label>

			<div className={cn('relative flex w-full  items-center', className)}>
				<Icon
					className={cn('text-body-md absolute left-4', {
						'text-black': isFocused || (value && String(value).trim() !== ''),
						'text-beige-100':
							!isFocused && (!value || String(value).trim() === ''),
					})}
					size="lg"
					name="magnifying-glass"
				/>
				<Input
					id={id}
					type="search"
					value={value}
					defaultValue={defaultValue}
					placeholder={placeholder}
					onChange={onChange}
					onFocus={() => setIsFocused(true)}
					onBlur={() => setIsFocused(false)}
					className={cn(
						'my-0 h-10 w-full rounded-md border bg-white py-4 pl-12 pr-12 ring-offset-background',
						'placeholder:text-muted-foreground placeholder:opacity-20',
						{
							'border-black': value && String(value).trim() !== '',
							'border-beige-100': !value || String(value).trim() === '',
							'focus:border-black focus:outline-none': true,
							'text-black': isFocused || (value && String(value).trim() !== ''),
							'text-beige-100':
								!isFocused && (!value || String(value).trim() === ''),
						},
						className,
					)}
					{...rest}
				/>
				{showClear && (
					<button
						type="button"
						onClick={onClear}
						className="absolute right-4 hidden cursor-pointer md:block"
					>
						<Icon size="sm" name="cross" />
					</button>
				)}
			</div>
		</div>
	)
}

export default SearchInputNew
