import { Link } from '@remix-run/react'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import SeparatorLine from '#app/components/common/separator-line'
import PlentyButtonsAndLinks from '#app/components/typography/plenty-buttons-and-links'
import PlentyBodyLink from '#app/components/ui/plenty-body-link'
import NoResultFound from '#app/features/clerk-search/components/search-no-result'
import ClerkProductCard from '#app/features/plp-clerk/clerk-components/clerk-product-card/clerk-product-card'
import ProductCardSkeleton from '#app/features/plp-clerk/clerk-components/clerk-product-card/clerk-skeleton-product-card'
import { useClerkVisitorStore } from '#app/store/clerk-visitor'
import { useDebounceSearch } from '#app/utils/misc'
import { logClickEvent } from '#app/utils/tracking/clerk-tracking'
import { type ClerkProduct } from '#types/product'
export type CategoryType = 'men' | 'kids' | 'women' | 'all'
interface SearchResponse {
	result: ClerkProduct[]
	estimated_total_count: number
	brands: string[]
}

interface PredictiveSearchResultsMobileProps {
	searchTerm: string
	category?: string
	onClose: () => void
	onSearch: () => void
	onTotalCountChange?: (count: number) => void
	onCategoryChange?: (category: CategoryType) => void
}

export function PredictiveSearchResultsMobile({
	searchTerm,
	category = 'all',
	onClose,
	onSearch,
	onTotalCountChange,
	onCategoryChange,
}: PredictiveSearchResultsMobileProps) {
	const [results, setResults] = useState<ClerkProduct[]>([])
	const [totalCount, setTotalCount] = useState(0)
	const [loading, setLoading] = useState(false)
	const { t } = useTranslation('search')
	const { clerkVisitor } = useClerkVisitorStore()
	const debouncedSearchTerm = useDebounceSearch(searchTerm, 500)
	const isStyleNumber = (value: string) => {
		return /^\d{8}$/.test(value)
	}

	const isCodeName = (value: string) => {
		return /^[A-Z\s]+$/.test(value) && value.includes(' ')
	}
	const getSearchType = (value: string) => {
		if (isStyleNumber(value)) return 'style'
		if (isCodeName(value)) return 'code'
		return 'regular'
	}
	useEffect(() => {
		async function fetchResults() {
			if (!debouncedSearchTerm) {
				onTotalCountChange?.(0)
				return
			}

			setLoading(true)

			const apiUrl = `/resources/clerkPredictive?query=${encodeURIComponent(
				debouncedSearchTerm,
			)}${category !== 'all' ? `&gender=${encodeURIComponent(category)}` : ''}`

			try {
				const response = await fetch(apiUrl)
				const data: SearchResponse = await response.json()
				setResults(data.result || [])
				setTotalCount(data.estimated_total_count)
				onTotalCountChange?.(data.estimated_total_count)
			} catch (error) {
				console.error('Search error:', error)
				setResults([])
				setTotalCount(0)
				onTotalCountChange?.(0)
			} finally {
				setLoading(false)
			}
		}

		fetchResults()
	}, [debouncedSearchTerm, category, onTotalCountChange])

	const handleProductClick = (productId: string, index: number) => {
		logClickEvent(productId, index, clerkVisitor!)
		onClose()
	}

	if (!searchTerm) return null

	if (loading || (searchTerm && searchTerm !== debouncedSearchTerm)) {
		return (
			<div>
				<div className="grid grid-cols-2 gap-2 pt-2 md:hidden">
					{Array.from({ length: 4 }, (_, index) => (
						<ProductCardSkeleton key={index} />
					))}
				</div>

				<div className="hidden w-full gap-2 md:flex">
					{Array.from({ length: 6 }, (_, index) => (
						<ProductCardSkeleton key={index} />
					))}
				</div>
			</div>
		)
	}

	const hasResults = results.length > 0
	const showNoResults = !loading && searchTerm.length > 0 && !hasResults

	return (
		<div className={`flex flex-col ${!hasResults ? 'md:w-fit' : ''}`}>
			{hasResults ? (
				<>
					{/* Comment in again with the suggestions */}
					<div className="py-3 md:hidden">
						<SeparatorLine />
					</div>
					<div className="flex flex-row justify-between pb-2 md:pt-0">
						<div>
							<PlentyButtonsAndLinks as="nav-md">
								{getSearchType(searchTerm) === 'regular'
									? t('product_count', { count: totalCount })
									: t(
											totalCount === 1
												? 'we_found_matching_one'
												: 'we_found_matching_other',
											{
												count: totalCount,
											},
									  )}
							</PlentyButtonsAndLinks>
						</div>
						{totalCount !== 1 && (
							<div onClick={onSearch} className="cursor-pointer">
								<PlentyBodyLink as="btn-md">
									{t('see_all_products')}
								</PlentyBodyLink>
							</div>
						)}
					</div>
					<div className={`grid grid-cols-2 gap-2 md:flex md:[&>a]:w-[168px]`}>
						{results.map((product, index) => (
							<Link
								key={product.id}
								to={`/product/${product.style_id}`}
								onClick={() => handleProductClick(product.id, index)}
							>
								<ClerkProductCard product={product} />
							</Link>
						))}
					</div>
				</>
			) : showNoResults ? (
				<div className="flex items-start self-start">
					<NoResultFound
						searchTerm={searchTerm}
						selectedCategory={category as CategoryType}
						onCategoryChange={category => {
							onCategoryChange?.(category)
						}}
						onClose={onClose}
					/>
				</div>
			) : null}
		</div>
	)
}
