import BlackBackgroundColorForImages from '#app/components/black-background-color-for-images'
import PlentyHeading from '#app/components/typography/plenty-heading'
import { type DiscountStoryblok } from '#types/component-types-sb'
import DiscountCowndown from './discount-countdown'

const DiscountImageAndImageText = ({ discount }: DiscountStoryblok) => {
	return (
		<div className="relative w-full">
			<img
				className="w-full rounded-t"
				src={discount.image?.filename}
				alt={discount.image?.alt || ''}
			/>
			{discount.blackBackground && (
				<BlackBackgroundColorForImages className="rounded-t" />
			)}
			<div className="absolute inset-0 flex items-center justify-center px-4 text-center">
				<PlentyHeading as="h5" className={`text-${discount.imageTextColor}`}>
					{discount.imageText}
				</PlentyHeading>
			</div>
			{/* Time remaining in the bottom-left corner */}
			{discount.storeDiscount && (
				<DiscountCowndown
					discount={discount}
					_uid={discount._uid}
					component={'discount'}
				/>
			)}{' '}
		</div>
	)
}

export default DiscountImageAndImageText
